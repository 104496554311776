export const menuItems = [{
    id: 1, label: 'menuItems.menu.text', isTitle: true
}, {
    id: 2, label: 'menuItems.dashboard.text', icon: 'fa-solid fa-chart-line', badge: {
        variant: 'success', text: 'menuItems.dashboard.badge'
    }, link: '/'
}, {
    id: 3, label: 'menuItems.patients.text', icon: 'fa-solid fa-users', link: '/patients-board', feature: 'exercises'
}, {
    id: 10, label: 'menuItems.pedisteps.text', icon: 'fa-solid fa-desktop', link: '/pedisteps', feature: 'pedisteps'
}, {
    id: 4, label: 'menuItems.chat.text', icon: 'fa-solid fa-message', link: '/chat', feature: 'chat'
}, {
    id: 5, label: 'menuItems.calendar.text', icon: 'fa-solid fa-calendar', link: '/calendar', feature: 'calendar'
}, {
    id: 6, label: 'menuItems.creator.text', icon: 'fa-solid fa-paintbrush', link: '/creator', badge: {
        variant: 'danger', text: 'menuItems.alpha.badge'
    }, feature: 'creator', disabled: true
}, {
    id: 7, label: 'menuItems.marketplace.text', icon: 'fa-solid fa-store', link: '/marketplace', badge: {
        variant: 'danger', text: 'menuItems.alpha.badge'
    }, feature: 'marketplace', disabled: true
}, {
    id: 8, label: 'menuItems.worldmap.text', icon: 'fa-solid fa-map', link: '/admin/map', feature: 'map', admin: true,
}, {
    id: 9, isLayout: true
},]
